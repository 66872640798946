import { Directive, ElementRef, inject, input, OnChanges, Renderer2 } from '@angular/core';

import { environment } from '@@environments/environment';

@Directive({
	selector: '[data-testid]',
	standalone: true
})
export class DataTestidDirective implements OnChanges {
	readonly contextSignal$ = input<string>(undefined, { alias: 'data-testid-context' });
	readonly #el = inject(ElementRef);
	readonly #renderer = inject(Renderer2);

	constructor() {
		if (environment.production) {
			this.#renderer.removeAttribute(this.#el.nativeElement, 'data-testid-context');
			this.#renderer.removeAttribute(this.#el.nativeElement, 'data-testid');
		}
	}

	ngOnChanges(): void {
		if (!environment.production) {
			if (this.contextSignal$()) {
				this.#renderer.setAttribute(this.#el.nativeElement, 'data-testid-context', this.contextSignal$());
			} else {
				this.#renderer.removeAttribute(this.#el.nativeElement, 'data-testid-context');
			}
		}
	}
}
